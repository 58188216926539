$enable-responsive-font-sizes: true;

$white: #fff;
$gray-100: #e6e6e6; 
$gray-200: #d9d9d9;
// $gray-300: #adbcc4;
// $gray-400: #6e6e6e;
$gray-500: #6e6e6e;
// $gray-600: #3d6478;
// $gray-700: #264d61;
// $gray-800: #0c344b;
// $gray-900: #082333;
$gray-1000: #1f1f1f;
$black: #000000;
$orange-100: #ff6600;
$orange-200: #fac090;
$orange-300: #fff0e6;

$navbar-bg-color: $gray-100; //  $gray-200; background-color navbar
$navbar-color: $gray-1000; // color for elements/text in navbar

$body-color: $gray-1000;  // sets the general color of all texts
$light: $white;
$dark: $black;
$text-muted: $gray-500;

$headings-color: $gray-1000;
$headings-font-family: 'Raleway', sans-serif;
$headings-font-weight: 500;

$font-family-sans-serif: 'Raleway', sans-serif;
// $font-family-monospace: 'Roboto Mono', monospace;
// $font-family-serif: 'Roboto Slab', serif;

$link-color: $gray-1000;  
// $link-decoration: underline;

$font-size-base: 1.125rem;

$headings-margin-bottom: 1.25rem;
$paragraph-margin-bottom: 1.5rem;
$spacer: 1.125rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 5),
  7: ($spacer * 6),
  8: ($spacer * 7),
  9: ($spacer * 8),
);

$border-radius: .45rem;
$border-radius-lg: .5rem;
$border-radius-sm: .3rem;
$blockquote-font-size: $font-size-base;

$code-color: $body-color;
$navbar-brand-font-size: 1rem;
$navbar-nav-link-padding-x: 1.25rem;
$navbar-padding-y: .4rem;

$font-size-cookie-banner: 0.75rem;

//Creates width classes like w-40, w-60 ...
$i : 10;
@while $i <= 90 {
  @if $i != 50 {
    .w-#{$i} {              //names like w-40, w-60
      width: #{"#{$i}%"};
    }
  }
  $i : $i + 10;
}
