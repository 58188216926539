* {
  font-family: $font-family-sans-serif; // the general font-family for everything
}

body {
  text-align: justify;
}

h1, h2, h3, h4 {
  text-align: left;
}

h1 {
	font-size: 2.0em;
}

h2 {
	font-size: 1.6em;
}

h3 {
	font-size: 1.4em;
}

h4 {
	font-size: 1.2em;
}

.homepage-image {
    @include media-breakpoint-up(lg) {
        position: absolute;
        width: 400px;
        right: 100px;
        top: 0;
    }
}

.author .name a, .author .social a {
    color: $gray-900;
    text-decoration: none;
}

blockquote {
    color: $gray-400;
    border-left: 6px solid $gray-400;
    padding-left: $spacer;
}

.blockquote_standout {
    color: $black;
    border-left: 6px solid $orange-100;
    padding-left: $spacer;
    font-size: 1.2em;
}

.blockquote_standout_more {
    color: $black;
    border-left: 6px solid $orange-100;
    padding-left: $spacer;
    font-size: 1.5em;
}

code {
    background-color: $gray-100;
    border-radius: $border-radius-sm;
    padding: .125rem .2rem;
}

code[class*=language-], pre[class*=language-] {
    font-family: $font-family-sans-serif;
    @include font-size($code-font-size);
}

figure.highlight pre:not([class*=language-]) {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    background-color: $gray-100;
}

pre code {
    padding: 0;
    background-color: transparent;
}

.latest h2 a, .navbar a, .card > a, a.badge {
    text-decoration: none;
}

figure.highlight {
    margin-left: -($grid-gutter-width / 2);
    margin-right: -($grid-gutter-width / 2);

    @include media-breakpoint-up(md) {
        margin-left: 0;
        margin-right: 0;
    }
}

img[data-src] {
    opacity: 0;
    @include transition($transition-fade);

    &.loaded {
        opacity: 1;
    }
}

#navbar-main-menu {
    transition: top 0.2s ease;

    .nav-item {
      max-width: fit-content;
      .nav-link {
        font-size: $navbar-brand-font-size;
      }
    }
}

ul.share.nav {
    .nav-link {
        padding: $nav-link-padding-y 0.2rem;
        text-align: center;
    }
}

#cookie-consent {
  bottom: 0%;
  z-index: 999;
}
.cookie-card {
    font-size: $font-size-cookie-banner;
    width: 350px;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: $white;
    background-clip: border-box;
    border: 1px solid $gray-200;
    border-radius: 6px;
    -webkit-box-shadow: 0px 0px 5px 0px $gray-600;
    -moz-box-shadow: 0px 0px 5px 0px $gray-600;
    box-shadow: 0px 0px 5px 0px $gray-600;
    @include media-breakpoint-up(md) {
        font-size: $font-size-base;
        margin-left: 0;
        margin-right: 0;
    }
}

.cookies a {
    text-decoration: none;
    color: $black;
    margin-top: 8px
}


#cookie\.okay {
  color: $white; // color of cookie button "Okay" (only visible before cookie is set)
  background-color: $orange-100; // background-color of cookie button "Okay" (only visible before cookie is set)
  margin-top: 8px
}

#cookie\.okay:hover {
    background-color: $orange-200;
}

.button {
  background-color: $orange-200;
  border-radius: 0px;
  border: none;
  color: white !important;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
}

.card-images {
  width: 100% !important;
  height: 60vh !important;
  max-height: 450px;
  object-fit: contain !important;
}

@media screen and (max-width: 992px){
  .card-images {
    width: 100% !important;
    height: 50vh !important;
    object-fit: contain !important;
  }
}

a, a:hover {
  text-decoration: none; // do not underline links, always|also on hover
}

a:hover {
  color: $orange-200;
}

// set colors of navbar
#navbar-main-menu {
  background-color: $navbar-bg-color;
  color: $navbar-color;
}

// set colors of footer
footer {
  background-color: $navbar-bg-color;
  color: $navbar-color;
  position: relative; // can't be static, because we want to drag it to the bottom
  bottom: 0; // drag footer to the bottom of the page
}

// sets colors of links/font-awesome icons in navbar and footer
#navbar-main-menu, footer {
  a {
      color: $gray-1000; // $orange-100; color of links in footer
      text-decoration: none; // do not underline links
  }

  a:hover {
      color: $orange-100; // color of links in footer on hover
      text-decoration: none; // do not underline links
  }

  a span {
    color: $gray-1000; // $orange-100;font-awesome "background" color
  }
  .fa-stack>svg:nth-child(2) {
    color: $white; // color of the font awesome icon itself
  }
  a span:hover {
    color: $orange-100; // font-awesome "background" color on hover
  }
  .fa-stack>svg:nth-child(2):hover {
    color: $white; // color of the font awesome icon itself on hover
  }

  .navbar-brand {
    color: $link-color; // color of "empirica regio" in navbar
  }
  .navbar-nav:nth-child(1) .nav-item .nav-link {
    color: $navbar-color; // color of the left navbar menu items (Regionaldaten, Reports, ..., Teams)
  }
  .navbar-nav:nth-child(1) .nav-item .nav-link:hover {
    color: $orange-100; // color of the left navbar menu items (Regionaldaten, Reports, ..., Teams) on hover
  }
  .lang-setter {
    color: $link-color; // color of the language Text (en|de)
  }

}
.card a:hover {
  color: $link-color;
}
.author-blog-post-list {
  list-style-type: decimal-leading-zero; // style of bullet points on author overview page (e.g. authors/jan-grade)
}

// CSS styles for all links inside of content-pages' (blogs, news, static pages like Regionaldaten content blocks
// does NOT AFFECT tags, author links, sharer (linkedin/xing/etc.), or images (d-block class; the "on hover" style does affect images)
.content-page {
  .content {
    a:not(d-block) {
      text-decoration: underline; // e.g. underline links, none (no decoration), overline, ...
      //color: red; // color of links
      //background-color: red; // background-color of links
      //outline: 5px solid blue; // border around the link, e.g. outline: 5px solid red;
    }
    a.d-block:hover {
      //text-decoration: underline; // e.g. underline links, none (no decoration), overline, ...
      //color: red; // color of links
      //background-color: red; // background-color of links
      outline: 1px solid $orange-200; // border around the link, e.g. outline: 5px solid red;
    }
    // use the following if you want to display a fone awesome icon left of a link, uncomment if you don't
    // a:not(.d-block):before {
    //    font-family: "FontAwesome";
    //    content: "\f0c1"; // icon: https://fontawesome.com/v5.15/icons/link?style=solid
    //    display: inline-block;
    //    padding-right: 3px;
    //    vertical-align: middle;
    //    font-weight: 900;
    // }
    div.team-card a:before {
      content: "";
    }
  }
}

// .figure {
//   display: block;
//   margin: auto;
// }

.homepage > hr {
  border: none;
  height: 2px;
  color: $gray-200;
  background-color: $gray-200;
}

.homepage_icons a:hover {
  color: $link-color;
}

// highlight the active navbar/main-menu item
#navbar-main-menu .navbar-nav .nav-item.active > .nav-link {
  font-weight: bold;
  color: $orange-100;
}
#navbar-main-menu .navbar-nav .nav-item.active > .nav-link:hover {
  color: black;
}
// give a border-bottom (over-complex solution for width: 100% but allows easily
// to adjust the width to other percentages
// .nav-item.active .nav-link:after {
    // content: "";
    // display: block;
    // width: 100%;
    // margin: auto;
    // border-bottom: solid;
// }

.cookie-consent-icon {
  color: $orange-100;
}

// .homepage_icons ul:nth-of-type(1) li:hover {
//   background: $orange-100;
// }

.homepage-image {
}
.homepage_icons {
  margin-left: -25%;
  margin-right: -25%;

  .homepage_icons_left {
    li {
      margin-left: 2rem;
    }
    a  {
      background: $orange-100;
    }
  }
  .homepage_icons_center {
    li {
      margin-left: 2rem;
    }
    a  {
      background: $orange-200;
    }
  }
  .homepage_icons_right {
    li {
      margin-left: 2rem;
    }
    a  {
      background: $gray-500;
    }
  }
  * > {
    @include media-breakpoint-up(md) {
      margin-left: 25%;
    }
  }
}

/* styling for links that pretend to be buttons */
a.btn {border-radius: .25rem; border: 1px solid transparent; padding: .5rem 1rem; color: $white; }
a.btn:hover { border-radius: .25rem; }

.carousel-control-prev-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
 }

.carousel-control-next-icon {
   background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
// for external links
// .content a[href^="http"]:not([href*="http//www.empirica-regio.de"]),
// .content a[href^="http"]:not([href*="http//www-test.empirica-regio.de"]) {
//     position: relative;
//  }

// .content a[href^="http"]:not([href*="http//www.empirica-regio.de"])::after,
// .content a[href^="http"]:not([href*="http//www-test.empirica-regio.de"])::after {
//   content: '\2192'; 
//   display: inline-block;
//   margin-left: 2px;
//   color: $orange-100;
// }

// for internal links
// .content a[href*="http//www.empirica-regio.de"]:not([href*="http//www.empirica-regio.de/images"]),
// .content a[href*="http//www-test.empirica-regio.de"]:not([href*="http//www-test.empirica-regio.de/images"]),
// .content a[href^="/"]:not([href*="/images"]) {
//     position: relative;
//     border-bottom: 2px solid $orange-100;
//     text-decoration: none;
//  }

// .content a[href^="http//www.empirica-regio.de"]:not([href*="http//www.empirica-regio.de/images"])::after,
// .content a[href*="http//www-test.empirica-regio.de"]:not([href*="http//www-test.empirica-regio.de/images"])::after,
// .content a[href^="/"]:not([href*="/images"])::after {
//   content: '\1F517';
//   margin-left: 2px;
//   color: $orange-100;
// }

.modal_request_trial {
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 50vw;
  }
}

// styles regarding menu/necessary for submenu
ul.navbar-nav li ul {
  display: block;
  position: static; /* Default to static position for mobile */
  background-color: #f8f8f8;
  border: 1px solid $gray-100;
}

.has_submenu {
  width: 75vw;
}
@media (min-width: 991px) {
  ul.navbar-nav li {
    position: relative;
  }

  ul.navbar-nav li ul {
    display: none;
    position: fixed; /* Position as absolute on larger screens */
  }
  .has_submenu {
    max-width: fit-content;
  }
}
ul.navbar-nav li:hover ul {
  display: block;
}

ul.navbar-nav li:hover ul {
  display: block;
}

ul.navbar-nav li ul li {
  display: block;
}

/* product overview list */
.productcard:hover{
  transform:scale(1.03);
}
.productcard a div {
  border-bottom-color: $orange-100 !important;
}
.productcard-arrow {
  text-color: $orange-100;
}

.bold {
  font-weight: bold;
}

/* cookie-manager */
#consent-notice {
  padding: 1rem 1rem;
  display: none;
  text-align: center;
  position: fixed;
  bottom: 0;
  /*width: calc(100% - 2rem)*/
  width: 100%;
  background: #222;
  color: rgba(255,255,255,0.8);
  span {
    margin-right: 1rem;
    a {
      color: inherit;
      text-decoration: underline;
      text-decoration-color: rgba(255,255,255,0.5);
    }
  }
  button {
    cursor: pointer;
    display: inline-block;
    width: auto;
  }
  .btn {
    margin-left: 0.5rem;
  }
  .manage-consent, .deny-consent, .approve-consent {
    color: white;
    border: 1px solid white;
    background: rgba(255,255,255,0.1);
    font-weight: normal;
  }
}

#consent-overlay {position: fixed; left: 0; top: 0; width: 100%; height: 100vh; display: none; background: rgba(0,0,0,0.75); z-index: 999999; overflow: auto; cursor: pointer;}
#consent-overlay.active {display: flex;}
#consent-overlay > div {background: white; width: 100%; max-width: 30rem; padding: 1.75rem; margin: auto; cursor: initial;}
#consent-overlay > div > div {display: flex; align-items: flex-start; margin-bottom: 1rem;}
#consent-overlay > div > div:last-child {margin: 0;}
#consent-overlay h3 {padding-top: 0;}
#consent-overlay input {margin-top: 0.3rem;}
#consent-overlay label {display: block;}
#consent-overlay .btn {margin-right: 0.5rem;}
#consent-overlay button.btn.save-consent {background: rgba(0,0,0,0.2); font-weight: normal;}
#consent-overlay button.btn.approve-consent {background: rgba(0,0,0,0.2); font-weight: normal;}

@media (max-width: 767px) {
    #consent-overlay > div {
      padding: 1.75rem 1rem;
    }
    #consent-notice span {
      display: block;
      padding-top: 3px;
      margin-bottom: 1.5rem;
    }
    #consent-notice button.btn {
      position: relative;
      bottom: 4px;
    }
}
