// /* raleway-regular - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: normal;
//   font-weight: 400;
//   src: url('../../static/fonts/raleway-v28-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-regular.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
// }

// /* raleway-500 - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: normal;
//   font-weight: 500;
//   src: url('../../static/fonts/raleway-v28-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-500.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
// }

// /* raleway-700 - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: normal;
//   font-weight: 700;
//   src: url('../../static/fonts/raleway-v28-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-700.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
// }

// /* raleway-100italic - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: italic;
//   font-weight: 100;
//   src: url('../../static/fonts/raleway-v28-latin-100italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-100italic.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-100italic.ttf') format('truetype'), /* Safari, Android, iOS */
// }

// /* raleway-200italic - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: italic;
//   font-weight: 200;
//   src: url('../../static/fonts/raleway-v28-latin-200italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-200italic.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-200italic.ttf') format('truetype'), /* Safari, Android, iOS */
// }

// /* raleway-300italic - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: italic;
//   font-weight: 300;
//   src: url('../../static/fonts/raleway-v28-latin-300italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-300italic.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-300italic.ttf') format('truetype'), /* Safari, Android, iOS */
// }

// /* raleway-italic - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: italic;
//   font-weight: 400;
//   src: url('../../static/fonts/raleway-v28-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-italic.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
// }

// /* raleway-500italic - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: italic;
//   font-weight: 500;
//   src: url('../../static/fonts/raleway-v28-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-500italic.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
// }

// /* raleway-700italic - latin */
// @font-face {
//   font-family: 'Raleway';
//   font-style: italic;
//   font-weight: 700;
//   src: url('../../static/fonts/raleway-v28-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-700italic.woff') format('woff'), /* Modern Browsers */
//        url('../../static/fonts/raleway-v28-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
// }

@import "_variables";
@import "~bootstrap/scss/bootstrap";
@import '~ekko-lightbox/dist/ekko-lightbox.css';
@import "empirica-regio";
